import {useCallback, useRef} from 'react'

export function useEventSource() {
  const sse = useRef<EventSource | null>(null)

  const subscribe = useCallback((url: string, callback) => {
    try {
      console.info('subscribe to eventSource url: ' + url)
      sse.current = new EventSource(url)
      sse.current.onmessage = event => {
        const data = JSON.parse(event?.data)
        if (data) {
          return callback(data)
        }
      }
    } catch (err) {
      console.error(`eventSource ${url} err: `, err)
    }
  }, [])

  return { sse, subscribe }
}
