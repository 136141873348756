export const adminMenuItems = [
  {
    key: '/admin/collections',
    title: 'Справочники',
  },
  {
    key: '/admin/schemas',
    title: 'Структуры',
  },
  {
    key: '/admin/registry',
    title: 'Регистры',
  },
  {
    key: '/admin/counters',
    title: 'Нумераторы',
  },
]
