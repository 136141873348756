import {ICancelModal, IEnumsData, IFormData} from "./interfaces";
import {ActivityItems, ActivityUpdateItem, ITimelineResponse} from "../../components/v2/activity-timeline/types/types";


export const HR_FORM_LOADING = 'HR_FORM_LOADING'
export const HR_FORM_DATA = 'HR_FORM_DATA'
export const HR_FORM_SUCCESS_UPDATE = 'HR_FORM_SUCCESS_UPDATE'
export const HR_FORM_ID_UPDATE = 'HR_FORM_ID_UPDATE'
export const HR_FORM_FAILED = 'HR_FORM_FAILED'
export const HR_CANCEL_FORM_DATA = 'HR_CANCEL_FORM_DATA'
export const HR_ENUMS_LOADING = 'HR_ENUMS_LOADING'
export const HR_ENUMS_DATA = 'HR_ENUMS_DATA'
export const HR_ENUMS_FAILED = 'HR_ENUMS_FAILED'
export const HR_TIMELINE_LOADING = 'HR_TIMELINE_LOADING'
export const HR_TIMELINE_DATA = 'HR_TIMELINE_DATA'
export const HR_TIMELINE_FAILED = 'HR_TIMELINE_FAILED'
export const HR_TIMELINE_CLEAR = 'HR_TIMELINE_CLEAR'
export const HR_TIMELINE_ADD_ITEM = 'HR_TIMELINE_ADD_ITEM'
export const HR_TIMELINE_UPDATE_ITEM = 'HR_TIMELINE_UPDATE_ITEM'
export const HR_TIMELINE_DELETE_ITEM = 'HR_TIMELINE_DELETE_ITEM'
export const HR_STAGE_PLAN_LOADING = 'HR_STAGE_PLAN_LOADING'
export const HR_STAGE_PLAN_DATA = 'HR_STAGE_PLAN_DATA'
export const HR_STAGE_PLAN_FAILED = 'HR_STAGE_PLAN_FAILED'
export const HR_STAGE_PLAN_CLEAR = 'HR_STAGE_PLAN_CLEAR'

/* ============== HR_FORM ============== */
export function setHrFormLoading() {
    return async dispatch => {
        dispatch({type: HR_FORM_LOADING})
    }
}

export function setHrFormSuccessUpdate() {
    return async dispatch => {
        dispatch({type: HR_FORM_SUCCESS_UPDATE})
    }
}

export function setHrFormCandidateId(id: string) {
    return async dispatch => {
        dispatch({type: HR_FORM_ID_UPDATE, payload: id})
    }
}
export function setHrFormData(formData: IFormData) {
    return async dispatch => {
        dispatch({type: HR_FORM_DATA, payload: formData})
    }
}

export function setHrFormFailed(error: string) {
    return async dispatch => {
        dispatch({type: HR_FORM_FAILED, payload: error})
    }
}

export function setHrCancelFormData(data: ICancelModal) {
    return async dispatch => {
        dispatch({type: HR_CANCEL_FORM_DATA, payload: data})
    }
}

/* ============== end HR_FORM ============== */


/* ============== HR_ENUMS ============== */
export function setHrEnumsLoading() {
    return async dispatch => {
        dispatch({type: HR_ENUMS_LOADING})
    }
}

export function setHrEnumsData(enumsData: IEnumsData) {
    return async dispatch => {
        dispatch({type: HR_ENUMS_DATA, payload: enumsData})
    }
}

export function setHrEnumsFailed(error: string) {
    return async dispatch => {
        dispatch({type: HR_ENUMS_FAILED, payload: error})
    }
}

/* ============== end HR_ENUMS ==============  */

/* ============== HR_TIMELINE ============== */

export function setHrTimelineLoading() {
    return async dispatch => {
        dispatch({type: HR_TIMELINE_LOADING})
    }
}

export function setHrTimelineData(formData: ITimelineResponse) {
    return async dispatch => {
        dispatch({type: HR_TIMELINE_DATA, payload: formData})
    }
}

export function setHrTimelineFailed(error: string) {
    return async dispatch => {
        dispatch({type: HR_TIMELINE_FAILED, payload: error})
    }
}

export function setHrTimelineClear() {
    return async dispatch => {
        dispatch({type: HR_TIMELINE_CLEAR})
    }
}
export function setHrStagePlanClear() {
    return async dispatch => {
        dispatch({type: HR_STAGE_PLAN_CLEAR})
    }
}

export function addHrTimeLineItem(activityItems: ActivityItems[]) {
    return async dispatch => {
        dispatch({type: HR_TIMELINE_ADD_ITEM, payload: activityItems})
    }
}

export function updateHrTimeLineItem(activityItem: ActivityUpdateItem) {
    return async dispatch => {
        dispatch({type: HR_TIMELINE_UPDATE_ITEM, payload: activityItem})
    }
}

export function deleteHrTimeLineItem(activityItem: ActivityUpdateItem) {
    return async dispatch => {
        dispatch({type: HR_TIMELINE_DELETE_ITEM, payload: activityItem})
    }
}



/* ============== end HR_TIMELINE ==============  */


/* ============== HR_STAGE_PLAN ============== */


export function setHrStagePlanLoading() {
    return async dispatch => {
        dispatch({type: HR_STAGE_PLAN_LOADING})
    }
}

export function setHrStagePlanData(data) {
    return async dispatch => {
        dispatch({type: HR_STAGE_PLAN_DATA, payload: data})
    }
}

export function setHrStagePlanFailed(error: string) {
    return async dispatch => {
        dispatch({type: HR_STAGE_PLAN_FAILED, payload: error})
    }
}

/* ============== end HR_STAGE_PLAN ==============  */
