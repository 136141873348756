import React, {useCallback, useEffect, useRef, useState} from "react";
import B2BLayout from "../../layouts/b2b";
//@ts-ignore
import moment, {Moment} from "moment";
import {Alert, Badge, BadgeProps, Calendar} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
    createSession,
    getTaskCalendarData,
    getTaskCalendarDataLoading,
    getTaskCalendarError,
    IDataByDayItem
} from "./model/selectors";
import {getHrTasksList, getTaskTypeHrTasksList} from "../../store/calendar/action-creators";
import {getFilterDateRange} from "./lib/getFilterDateRange";
import {getPreviewTaskItemData} from "./model/services/getPreviewTaskItemData";
import {getDetailTaskItemData} from "./model/services/getDetailTaskItemData";
import {TaskDetailModal} from "./ui/task-detail-modal/task-detail-modal";
import {TasksCalendarCreateTaskModal} from "./ui/tasks-calendar-create-task-modal/tasks-calendar-create-task-modal";
import {HStack} from "../../ui/Stack";
import FiltersList from "../../components/v2/filters";
import {ITaskFilter} from "./model/types/ITaskFilter";
import {useHistory} from "react-router-dom";
import {useFilterParams} from "./model/hooks/useFilterParams";

const breadcrumbs = [{
    href: '/tasks',
    title: 'Задачи',
}]

const AdminNewObjectPage = () => {
    const {sessionData} = useSelector(createSession);
    const history = useHistory();
    const dispatch = useDispatch();

    const dataByDay = useSelector(getTaskCalendarData);
    const isLoading = useSelector(getTaskCalendarDataLoading);
    const error = useSelector(getTaskCalendarError);
    const {fields, urlParams} = useFilterParams();

    const isLeader = sessionData.roles.includes(19)

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const [value, setValue] = useState<Moment>(() => moment());

    const [currentDetailData, setCurrentDetailData] = useState<IDataByDayItem[]>([]);
    const targetDate = useRef(moment());

    const filterRef = useRef<ITaskFilter>({
        id: urlParams?.id,
        stage: urlParams?.stage,
        task_type: +urlParams?.task_type,
        phone: urlParams?.phone,
        responsible: +urlParams?.responsible,
    } as ITaskFilter)

    const clearFilter = (filter) => Object.entries(filter)
        .filter(([_, v]) => Boolean(v))
        .reduce((acc, [k, v]) => ({...acc, [k]: v}), {});

    useEffect(() => {
        let mounted = true
        if (mounted) {
            dispatch(getTaskTypeHrTasksList())
            const filter = {
                ...clearFilter(filterRef.current),
                dates: getFilterDateRange(targetDate.current)
            }

            if (!isLeader && !sessionData?.user?.id) {
                return;
            }
            if (!isLeader && sessionData?.user?.id) {
                filter['responsible'] = sessionData.user.id;
            }
            //@ts-ignore
            if (!filter?.stage){
                filter['stage'] = 'notDone'
            }

            dispatch(getHrTasksList(filter))
        }
        return () => {
            mounted = false
        };
    }, [dispatch, sessionData])

    const onSelect = useCallback((newValue: Moment) => {
        setValue(newValue);
    }, []);

    const handleCancelModal = useCallback(() => {
        setIsOpenModal(false);
    }, []);


    const onPanelChange = useCallback(async (newValue: Moment, mode: string) => {
        setValue(newValue);
        if (mode === 'month') {
            if (newValue.format("YYYY.MM") !== targetDate.current.format("YYYY.MM")) {
                targetDate.current = newValue;

                const filter = {
                    ...clearFilter(filterRef.current),
                    dates: getFilterDateRange(newValue)
                }
                if (!isLeader && !sessionData?.user?.id) {
                    return;
                }
                //@ts-ignore
                if (!filter?.stage){
                    filter['stage'] = 'notDone'
                }
                if (!isLeader && sessionData?.user?.id) {
                    filter['responsible'] = sessionData.user.id;
                }
                dispatch(getHrTasksList(filter));
            }
        }
    }, [dispatch]);

    const clickCell = useCallback((value: Moment) => () => {
        const detailData = getDetailTaskItemData(value, dataByDay)
        setCurrentDetailData(detailData)
        setIsOpenModal(true)
    }, [dataByDay])

    const dateCellRender = useCallback((value: Moment) => {
        const listData = getPreviewTaskItemData(value, dataByDay);
        if (!listData.length) {
            return
        }

        return (
            <ul style={{height: '100%', cursor: 'pointer'}} onClick={clickCell(value)}>
                {listData.map(item => (
                    <li key={item.content}>
                        <Badge status={item.type as BadgeProps['status']} text={item.content}/>
                    </li>
                ))}
            </ul>
        );
    }, [dataByDay, isLoading]);

    const handlerFilterChange = useCallback((data) => {
        const filter = {
            ...data,
            dates: getFilterDateRange(targetDate.current)
        }
        filterRef.current = {...data}

        if (!isLeader && !sessionData?.user?.id) {
            return;
        }
        //@ts-ignore
        if (!filter?.stage){
            filter['stage'] = 'notDone'
        }

        if (!isLeader && sessionData?.user?.id) {
            filter['responsible'] = sessionData.user.id;
        }
        history.push({search: new URLSearchParams(data).toString()})
        dispatch(getHrTasksList(filter));

    }, [history, dispatch])

    return (
        <B2BLayout breadcrumbs={breadcrumbs}>
            <HStack align={'end'} justify={"end"} style={{marginBottom: '20px'}}>
                <TasksCalendarCreateTaskModal
                    entityType={"hr"}
                    titleInputSearch={'ID отклика'}
                />
            </HStack>

            {
                !isLoading && error && (
                    <Alert type={"error"} message={'При выполнении запроса возникла ошибка' + error}/>
                )
            }
            <FiltersList
                filters={fields}
                onChange={handlerFilterChange}
            />
            <Calendar
                value={value}
                dateCellRender={dateCellRender}
                onPanelChange={onPanelChange}
                onSelect={onSelect}
            />
            <TaskDetailModal
                onCancel={handleCancelModal}
                isOpen={isOpenModal}
                data={currentDetailData}
            />
        </B2BLayout>
    )

}
export default AdminNewObjectPage;
