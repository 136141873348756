import React, { useCallback, useMemo } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import { ConfigProvider } from 'antd'
import { IDate } from './interface'
import locale from 'antd/es/locale/ru_RU'

function InputDateTime({ value, onChange, UISchema }: IDate) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])

  const handleChange = useCallback(nextValue => onChange(nextValue && nextValue.toISOString()), [
    onChange,
  ])
  return (
    <ConfigProvider locale={locale}>
      <DatePicker
        showTime={{ format: 'HH:mm' }}
        format="DD.MM.YYYY HH:mm"
        onChange={handleChange}
        value={value && moment(value)}
        disabled={disabled}
      />
    </ConfigProvider>
  )
}

export default InputDateTime
