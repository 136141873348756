import {DashOutlined} from '@ant-design/icons';
import {message, Spin, Upload, UploadProps} from 'antd';
import React, {memo, ReactElement, useCallback, useContext, useMemo, useState} from 'react';
import {ContentWrapper, UploadWrapper, FileButton, FileLink} from "./styles";
import {UploadFile} from "antd/lib/upload/interface";
import DefaultJsonFormContext from "../../components/v2/default-json-form/context/default-json-form-context";

interface UploadFilesProps {
    value?: any,
    schema?: any,
    onChange?: (value) => void,
    UISchema?: any,
}

const UploadFilesS3: React.FC<UploadFilesProps> = (props: UploadFilesProps) => {
    const {UISchema, value, schema, onChange} = props;
    // @ts-ignore
    const {entityType, entityId} = useContext(DefaultJsonFormContext)
    const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema]);
    const multiple = useMemo(() => Boolean(UISchema?.options?.multiple), [UISchema]);
    const required = useMemo(() => Boolean(UISchema?.required), [UISchema]);
    const [isLoading, setIsLoading] = useState(false);
    const [isBorder, setIsBorder] = useState(false);
    const error = required && !value;

    const action = useMemo(() => {
        if (entityType && entityId) {
            return `/api/${entityType}/request/${entityId}/add-file`
        }
        return `/api/uploads`
    }, [entityType, entityId]);

    const handleOnChange = useCallback(async ({file, fileList}) => {
        const {status, error} = file;
        const isDone = status === 'done'

        if (error) {
            message.error(`${file.name} не удалось загрузить`)
            setIsLoading(false)
        }
        if (isDone) {
            message.success(`${file.name} загружен`)
            setIsLoading(false)
            onChange?.(fileList.map((loadedFile) => loadedFile.response?.files?.[0]))
        }

        setIsBorder(Boolean(fileList?.length))
    }, [])


    const defaultProps: UploadProps = useMemo(() => ({
        name: 'file',
        multiple: multiple,
        action: action,
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
        data: (_) => ({title: schema?.key || 'file'}),
        itemRender: (
            originNode: ReactElement,
            file: UploadFile,
        ) => (
            <ContentWrapper disabled={disabled || isLoading} error={error} className="antd-input antd-input-container">
                <FileLink href={file?.url}>{file.name}</FileLink>
                {/*{!disabled && multiple && <CloseCircleOutlined onClick={actions.remove}/>}*/}
            </ContentWrapper>
        ),
        beforeUpload: () => {
            setIsLoading(true)
        }
    }), [entityType, entityId, disabled, multiple])


    return (
        <UploadWrapper className="custom-upload-wrapper" isBorder={!Boolean(isBorder)}>
            <Upload {...defaultProps} disabled={disabled} onChange={handleOnChange}>
                <FileButton disabled={disabled || isLoading} className="ant-input">
                    {isLoading ? <Spin size="small"/> : <DashOutlined/>}
                </FileButton>
            </Upload>
        </UploadWrapper>
    )
};

export default memo(UploadFilesS3)
