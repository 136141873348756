import {createSelector} from 'reselect'
import {objectMap} from '../../../../../lib/utils/get-table-transform-schema-and-form-data'
import dayjs from 'dayjs'
import {convertData2JsonForm} from '../../../../shared/lib/convertData2JsonForm'
import {addEnumValBySchema} from '../../../../shared/lib/addEnumValBySchema'
import {ActivityItems} from '../../../../components/v2/activity-timeline/types/types'


const selectHr = state => state.hr

const selectHrForm = createSelector(
  selectHr,
  ({form}) => form
)

const selectHrEnums = createSelector(
  selectHr,
  ({enums}) => enums
)

const selectHrCancelModal = createSelector(
  selectHr,
  ({cancelModal}) => cancelModal
)

const selectHrStagePlan = createSelector(
  selectHr,
  ({stagePlan}) => stagePlan
)

export const getCandidateId = createSelector(
  selectHr,
  ({id}) => id
)

export const getHrFormLoading = createSelector(
  selectHrForm,
  ({isLoading}) => isLoading
)

export const getHrFormError = createSelector(
  selectHrForm,
  ({error}) => error
)

export const getHrFormData = createSelector(
  selectHrForm,
  ({data}) => data
)

export const getHrStageCurrent = createSelector(
  getHrFormData,
  (formData) => formData?.stages?.current
)

export const getHrEnumsData = createSelector(
  selectHrEnums,
  ({data}) => data
)

export const getVisibleFormData = createSelector(
  [getHrFormData, getHrEnumsData],
  (formData, enums) => {
    if (formData && enums) {
      const {
        candidate: candidateData,
        request: requestData,
        modal: modalData
      } = formData

      if (!candidateData || !requestData) {
        return false
      }

      const {
        resultObject: candidate,
        resultSchema: candidateFieldsSchema
      } = convertData2JsonForm(candidateData)

      const {
        resultObject: request,
        resultSchema: requestFieldsSchema
      } = convertData2JsonForm(requestData)

      const {
        resultObject: modal,
        resultSchema: modalFieldsSchema
      } = convertData2JsonForm(modalData?.fields)

      const enumItems = enums?.['enum'] || []

      if (enums?.['city']) {
        enumItems.city_id = Object.values(objectMap(enums['city'], ([key, value]) => {
          return [key, {id: parseInt(key), value: value}]
        }))
      }

      if (enums?.['region']) {
        enumItems.region_id = Object.values(objectMap(enums['region'], ([key, value]) => {
          return [key, {id: parseInt(key), value: value}]
        }))
      }

      Object.keys(enumItems).forEach(code => {
        if (
          candidateFieldsSchema?.properties.hasOwnProperty(code)
          || requestFieldsSchema?.properties.hasOwnProperty(code)
          || modalFieldsSchema?.properties.hasOwnProperty(code)
        ) {
          const enumValues = enums?.['enum']?.[code] || {}
          const oneOf: Record<string, string>[] = []

          Object.keys(enumValues).forEach(enumValue => {
            return oneOf.push({
              const: enumValues[enumValue].id,
              title: enumValues[enumValue].value
            })
          })

          if (candidateFieldsSchema?.properties.hasOwnProperty(code)) {
            addEnumValBySchema(candidateFieldsSchema, code, oneOf, enumValues)
          }
          if (requestFieldsSchema?.properties.hasOwnProperty(code)) {
            addEnumValBySchema(requestFieldsSchema, code, oneOf, enumValues)
          }
          if (modalFieldsSchema?.properties.hasOwnProperty(code)) {
            addEnumValBySchema(modalFieldsSchema, code, oneOf, enumValues)
          }
          if (candidate.hasOwnProperty(code)) {
            candidate[code] = candidate[code] ? parseInt(candidate[code]) : null
          }
          if (request.hasOwnProperty(code)) {
            request[code] = request[code] ? parseInt(request[code]) : null
          }
          if (modal.hasOwnProperty(code)) {
            request[code] = request[code] ? parseInt(request[code]) : null
          }
        }
      })

      return {
        candidateFieldsSchema,
        requestFieldsSchema,
        dateCreated: dayjs(requestData?.['created_at']).format('DD.MM.YYYY HH:mm') || '',
        candidate,
        request,
        modal: modalData ? modal : null,
        modalFieldsSchema: modalData ? modalFieldsSchema : null,
        modalTitle: modalData?.title,
      }
    }
  },
)

export const getVisibleCancelModal = createSelector(
  [selectHrCancelModal, getHrEnumsData],
  (cancelModal, enums) => {
    if (cancelModal && enums) {
      const {fields, title} = cancelModal
      if (!fields) {
        return false
      }

      const {
        resultObject,
        resultSchema
      } = convertData2JsonForm(fields)

      const enumItems = enums?.['enum'] || []

      Object.keys(enumItems).forEach(code => {
        if (resultSchema?.properties.hasOwnProperty(code)) {
          const enumValues = enums?.['enum']?.[code] || {}
          const oneOf: Record<string, string>[] = []

          Object.keys(enumValues).forEach(enumValue => {
            return oneOf.push({
              const: enumValues[enumValue].id,
              title: enumValues[enumValue].value
            })
          })

          if (resultSchema?.properties.hasOwnProperty(code)) {
            addEnumValBySchema(resultSchema, code, oneOf, enumValues)
          }

          if (resultObject.hasOwnProperty(code)) {
            resultObject[code] = resultObject[code] ? parseInt(resultObject[code]) : null
          }
        }
      })

      return {
        title,
        fields: resultObject,
        schema: resultSchema
      }
    }
  },
)

export const isVisibleCancelModal = createSelector(
  [getHrFormData, selectHrCancelModal],
  (data, cancelModal) => {
    return cancelModal && data?.stages?.items
      && (data?.stages?.current < (Object.values(data?.stages?.items).length - 1))
  }
)

const selectTimeline = createSelector(
  selectHr,
  ({timeline}) => timeline
)

export const selectTimelineData = createSelector(
  selectTimeline,
  ({data}) => data
)

export const selectTimelineFiles = createSelector(
  selectTimelineData,
({items}: { items: ActivityItems[] }) => {
    if (!items || items?.length === 0) {
      return []
    }

    return items?.map((activityItems) => {
      const files = activityItems?.items?.filter((activityItem) => activityItem.type === 'file')
      return files?.length ? {...activityItems, items: files} : undefined
    }).filter((_) => Boolean(_)) as ActivityItems[]
  }
)

export const selectTimelineIsLoading = createSelector(
  selectTimeline,
  ({isLoading}) => isLoading
)

export const selectTimelineError = createSelector(
  selectTimeline,
  ({error}) => error
)

export const selectStagePlanData = createSelector(
    selectHrStagePlan,
    ({items}) => items
)

export const selectStagePlanIsLoading = createSelector(
  selectHrStagePlan,
  ({isLoading}) => isLoading
)

export const selectStagePlanError = createSelector(
  selectHrStagePlan,
  ({error}) => error
)

const selectChatAvito = state => state.chatAvito

export const selectChatAvitoIsLoading = createSelector(
  selectChatAvito,
  ({ isLoading }) => isLoading
)

export const selectChatAvitoData = createSelector(
  selectChatAvito,
  ({ data }) => data
)

export const selectChatAvitoError = createSelector(
  selectChatAvito,
  ({ error }) => error
)
