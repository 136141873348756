import React, {ReactNode, useCallback, useMemo} from 'react';
import {Steps} from 'antd';
import {classNames} from "../../../lib/classNames/classNames";
import cls from "../Steps/Steps.module.css";

type Status = 'wait' | 'process' | 'finish' | 'error';
type Direction = 'horizontal' | 'vertical';

interface StepItem {
    description?: ReactNode;
    disabled?: boolean;
    icon?: ReactNode;
    status?: Status;
    subTitle?: ReactNode;
    name: string,
    code: string,
}

interface HistoryItem {
    code?: string,
    date_start?: string,
    date_end?: string,
}

export interface StepsProps {
    className?: string;
    current?: number;
    direction: Direction;
    items: StepItem[] | [],
    history?: HistoryItem[]
    onChange?: (current) => void
}

export const StepsUi: React.FC<StepsProps> = (props: StepsProps) => {
    const {
        direction,
        current,
        className,
        items,
        history = [],
        onChange,
        ...otherProps
    } = props;
    const {Step} = Steps;

    const handleOnChange = useCallback((current) => {
        onChange?.(current);
    }, [])

    // 0:{name: "На рассмотрении", code: "work"}
    // 1:{name: "Новый отклик", code: "new"}
    // 2:{name: "Приглашение на собеседование", code: "invitation"}
    // 3:{name: "Испытательный срок", code: "probation"}
    // 4:{name: "Завершен", code: "done"}

    const optionsList = useMemo(
        () =>
            items?.map((optionItem) => {
                if (history.length !== 0) {
                    if (history.find((historyItem) => historyItem?.code === 'done')) {
                        const historySteps = history.map((historyItem) => historyItem.code)
                        return <Step
                            title={optionItem.name}
                            key={optionItem.code}
                            status={historySteps.includes(optionItem.code) ? 'finish' : 'error'}
                        />
                    }
                }

                return <Step
                    title={optionItem.name}
                    key={optionItem.code}
                />
            }),
        [items, history],
    );


    return <Steps
        className={classNames(cls.Step, {}, [className])}
        direction={direction}
        current={current}
        // @ts-ignore
        onChange={handleOnChange}
        {...otherProps}
    >
        {optionsList}
    </Steps>
};

