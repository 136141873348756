import React, { ChangeEventHandler, useCallback, useMemo, useState } from 'react'
import { Form, Tooltip } from 'antd'
import { Select } from 'antd/es'
import Search from 'antd/es/input/Search'
import { DateRangePicker } from '../../input-date-range/styles'
import { datesToMoment, datesToString, objectsIsEqual } from './utils'
import { InputInteger, InputPhone } from './elements'

function FiltersList({ filters = [], onChange }: { filters: filtersType[]; onChange: any }) {
  const [values, setValues] = useState<any>(
    filters.reduce((obj, cur) => ({ ...obj, [cur.name]: cur.value }), {}),
  )

  const onChangeHandler = useCallback(
    current => {
      const newState = { ...values, ...current }
      if (!objectsIsEqual(newState, values)) {
        onChange(
          Object.fromEntries(Object.entries(newState).filter(([, value]) => value !== undefined)),
        )
        setValues(newState)
      }
    },
    [onChange, values],
  )

  /**
   * Возврачает инпут, в соответствии с входящими параметрами
   *
   * @param type
   * @param options
   * @param placeholder
   * @param name
   * @param disabled
   */
  const getInput = ({ type, options, placeholder, name, disabled }: filtersType) => {
    const width = options?.width || 200
    const value = values?.[name] || null
    if (type === 'input-string') {
      return (
        <Tooltip title={options?.tooltip}>
          <Search
            allowClear
            placeholder={placeholder as string || 'Заполните'}
            defaultValue={value}
            style={{ width }}
            onSearch={v => onChangeHandler({ [name]: v && v.length ? v : undefined })}
          />
        </Tooltip>
      )
    }
    if (type === 'input-integer') {
      return (
        <Tooltip title={options?.tooltip}>
          <div>
            <InputInteger
              defaultValue={value}
              onSearch={v => onChangeHandler({ [name]: v ? +v : undefined })}
              onChange={() => {}}
            />
          </div>
        </Tooltip>
      )
    }
    if (type === 'phone') {
      return (
        <Tooltip title={options?.tooltip}>
          <div>
            <InputPhone
              defaultValue={value}
              onChange={v => onChangeHandler({ [name]: v ? +v : undefined })}
            />
          </div>
        </Tooltip>
      )
    }
    if (type === 'select') {
      return (
        <Tooltip title={options?.tooltip}>
          <Select
            allowClear
            placeholder={placeholder || 'Выберите'}
            defaultValue={value}
            options={options?.enum || []}
            disabled={disabled || false}
            style={{ width }}
            onChange={v => onChangeHandler({ [name]: v })}
          />
        </Tooltip>
      )
    }
    if (type === 'dates') {
      return (
        <Tooltip title={options?.tooltip}>
          <DateRangePicker
            defaultValue={datesToMoment(value)}
            placeholder={ placeholder as [string, string] | undefined }
            style={{ width }}
            onChange={v => onChangeHandler({ [name]: datesToString(v) })}
          />
        </Tooltip>
      )
    }
    return null
  }

  /**
   * Формирует список для вывода
   */
  const filtersList = useMemo(() => {
    return filters.map(item => {
      return {
        render: getInput(item),
        name: item.name,
        key: item.name,
      }
    })
  }, [filters, getInput])

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', position: 'relative', width: '100%', marginBottom: 10 }}>
      {filtersList.map(({ render, name, key }) => (
        <Form.Item key={key} name={name} style={{ marginBottom: 3 }}>
          {render}
        </Form.Item>
      ))}
    </div>
  )
}

export default FiltersList
