import React from "react";
import {LogoSTL, LogoZEO} from "../../../../assets/icons";

type BrandType = 'ZEO' | 'STL'

export const getBrandIcon = (brand?: BrandType) => {
    switch (brand) {
        case 'ZEO':
            return <LogoZEO/>
        case'STL':
            return <LogoSTL/>
        default:
            return null
    }
}
