import React, { useMemo } from 'react'
import { Input } from 'antd'
import { IInputText } from './interface'

function InputText({ value, onChange, schema, UISchema }: IInputText){
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const options = useMemo(() => {
    const isTextArea = (schema?.options?.type === 'textarea') || (UISchema?.options?.type === 'textarea')
    return {
      type: isTextArea ? 'textarea' : 'text',
      minRows: UISchema?.options?.minRows ? UISchema.options?.minRows : 1,
      maxRows: UISchema?.options?.maxRows ? UISchema.options?.maxRows : 1,
    }
  }, [schema, UISchema])
  if(options.type === 'textarea' ){
    return <Input.TextArea autoSize={{ minRows: options.minRows, maxRows: options.maxRows }} value={value} onChange={onChange} disabled={disabled}/>
  }
  return <Input  value={value} onChange={onChange} disabled={disabled}/>
}

export default InputText