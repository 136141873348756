import BoxClient from './box-client'
import {notification} from 'antd'
import React, {memo, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {createSelector} from "reselect";
import {useSelector} from 'react-redux'
import fetchAPI from "../../../lib/utils/fetch-api";

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

const TopMenu = () => {
  const {sessionData} = useSelector(createSession)
  const history = useHistory()
  const [isOpen, setOpen] = useState<boolean>(false)
  const [clientId, setClientId] = useState(753)

  const openNotification = (data, onClick) => {
    const args = {
      message: <>Входящий звонок</>,
      key: data?.phone,
      description: `${data?.phone || ''} ${' ' + data?.name || ''}`,
      duration: 0,
      style: {background: '#88baff'},
      onClick
    }
    notification.open(args)
  }

  const openNotificationProduction = async (data, onClick) => {
    const payments = await fetchAPI(`/api/web-hooks/add-payment-contract`, {
      method: 'POST', body: JSON.stringify({
        'targetId': Number(+data.parent_id),
        pay: {
          sum: 0,
          date: ''
        }
      })
    })
    console.log(data, payments)
    const hasDebt = +payments.sum - +payments.paymentsSumAll > 0
    const message = (<>
      Заказ №{data.parent_id} по производственному заказу №{data.data.attributes["production-order-number"]} готов
      к отгрузке
      {hasDebt ? (<><br/><span>по нему есть задолженность!</span></>) : null}
    </>)
    const args = {
      message: message,
      duration: 0,
      style: {background: hasDebt ? '#e38d82' : '#45c915'},
      onClick
    }
    notification.open(args)
  }

  useEffect(() => {
    const eventSource = new EventSource(`/api/web-hooks/sse`, {withCredentials: true})
    eventSource.addEventListener('manufactureIsReady', event => {
      const data = JSON.parse(event.data)
      if (sessionData.roles.includes(2) && sessionData.organization.id === +data.owner_organization) {
        const onClick = () => {
          history.push(`/order/${data.id}/edit`)
        }
        openNotificationProduction(data, onClick)
      }
    })

    eventSource.addEventListener('clientCall', event => {
      const data = JSON.parse(event.data)
      const onClick = () => {
        setClientId(data?.['clientId'] || 0)
        setTimeout(() => {
          setOpen(true)
        }, 500)
      }
      openNotification(data, onClick)
    })

    return () => {
      eventSource.close()
    }

  }, [sessionData])

  return <BoxClient isOpen={isOpen} setOpen={setOpen} id={clientId}/>
};

export default memo(TopMenu)
