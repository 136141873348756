import styled from 'styled-components';
import { Button } from 'antd'

interface IHeadingStyled {
  disabled: boolean;
  error: boolean;
}

export const ContentWrapper = styled.div<IHeadingStyled>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  flex-direction: row;
  padding: 2px 5px !important;
  border-radius: 3px;
  border-color: ${props => props.error ? '#ff4d4f': '#d9d9d9'};
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  background-color: ${props => props.disabled ? '#f5f5f5': 'unset'};

  &:hover, &:focus {
    border-color: ${props => props.error ? '#ff4d4f': props.disabled ? '#d9d9d9': '#40a9ff'};
  }
  &:hover svg, &:focus svg {
    visibility: visible;
  }
`;

export const UploadWrapper = styled.div<{isBorder: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &>span {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    width: 100%;
    border-radius: 3px;
    border: ${({ isBorder = false }) => (isBorder ? '1px solid #d9d9d9' : 'none')};
  }
  
 & .ant-upload-list {
   width: 100%;
 }
`;


export const FileLink = styled.a`
  width: 100%;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileNoLink = styled.span`
  white-space: nowrap;
  color: #d9d9d9;
`;

export const FileLinkWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  white-space: nowrap;
  align-items: center;
  padding-left: 10px !important;
  padding-right: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    font-size: 16px;
    color: #d9d9d9;
    visibility: ${({ isMobile = false }) => (isMobile ? 'visible' : 'hidden')};
  }
`;

export const FileButton = styled(Button)`
  width: 32px;
  padding: 0 !important;
  border-color: #d9d9d9;
`;
