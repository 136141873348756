import React, {memo, Suspense, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getHrStageCurrent,
    selectStagePlanData,
    selectStagePlanError,
    selectStagePlanIsLoading,
} from "../selectors";
import {StagePlan} from "../../../../components/v2/stage-plan";
import {getHrStagePlanData} from "../../../../store/hr/action-creators";
interface HrStagePlanProps {
    className?: string
    id: string | number
}

export const HrStagePlan: React.FC<HrStagePlanProps> = memo((props: HrStagePlanProps) => {
    const {className, id} = props;
    const dispatch = useDispatch();
    const stagePlanIsLoading = useSelector(selectStagePlanIsLoading);
    const stagePlanError = useSelector(selectStagePlanError);
    const stagePlanFiles = useSelector(selectStagePlanData);
    const stage = useSelector(getHrStageCurrent);

    useEffect(() => {
        let mounted = true
        if (stage !== undefined && id !== 'new') {
            if (mounted) {
                dispatch(getHrStagePlanData(id));
            }
        }
        return () => {
            mounted = false
        };
    }, [stage, id, dispatch])

    return (
        <Suspense fallback={''}>
            <StagePlan
                isLoading={stagePlanIsLoading}
                items={stagePlanFiles}
                error={stagePlanError}
                className={className}
            />
        </Suspense>
    );
});
