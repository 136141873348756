export const deleteTimelineItem = (deletedItem, timelineItems, timelineDates) => {
    const index = timelineDates?.findIndex((date) => date == deletedItem.date);
    const itemsCount = timelineItems[index].items.length;
    if (itemsCount === 1) {
        timelineItems = [...timelineItems.splice(index, 1)];
        timelineDates = [...timelineDates.splice(index, 1)];
    } else {
       const newItems = timelineItems[index].items?.filter((activityItem, indexActivityItem) => {
           if (activityItem.type === 'file') {
               if (indexActivityItem !== deletedItem.index)
               return activityItem
           }

          return (indexActivityItem !== deletedItem.index && activityItem.id !== deletedItem.data.id) ? activityItem : '';
       })
        timelineItems[index].items = [...newItems];
    }

    return {
        timelineItems,
        timelineDates
    }
}
