export const addEnumValBySchema = (schema, code, oneOf, enumValues) => {
    Object.assign(schema?.properties[code], {
        oneOf: oneOf,
        'enum': enumValues.map(item => item.id),
        view: schema?.properties[code]?.view || {'name': 'select-from-enum'},
        options: {
            ...schema?.properties[code].options,
            allowClear: true
        }
    })
    return;
}
