import fetchAPI from '../../../lib/utils/fetch-api'
import {
  RESTORE_SESSION_REQUESTED,
  RESTORE_SESSION_FAILED,
  RESTORE_SESSION_DONE,
  DELETE_SESSION_REQUESTED,
  DELETE_SESSION_FAILED,
  DELETE_SESSION_DONE,
} from './actions'

export function getLoadSessionAction() {
  return async dispatch => {
    dispatch({
      type: RESTORE_SESSION_REQUESTED,
    })
    try {
      const response = await fetchAPI('/api/session', {
        method: 'GET',
      })

      if (response['errors']) {
        return dispatch({
          type: RESTORE_SESSION_FAILED,
          payload: response,
          error: true,
        })
      }
      return dispatch({
        type: RESTORE_SESSION_DONE,
        payload: response,
      })
    } catch (err) {
      return dispatch({
        type: RESTORE_SESSION_FAILED,
        payload: err,
        error: true,
      })
    }
  }
}

export function getDeleteSessionAction(): (dispatch: any) => Promise<any> {
  return async dispatch => {
    dispatch({
      type: DELETE_SESSION_REQUESTED,
    })
    try {
      const response = await fetchAPI('/api/session', {
        method: 'DELETE',
      })
      if (response['errors']) {
        return dispatch({
          type: DELETE_SESSION_FAILED,
          payload: response,
          error: true,
        })
      }
      return dispatch({
        type: DELETE_SESSION_DONE,
        payload: response,
        error: true,
      })
    } catch (err) {
      return dispatch({
        type: DELETE_SESSION_FAILED,
        payload: err,
        error: true,
      })
    }
  }
}
