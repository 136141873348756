import {
    HR_FORM_LOADING,
    HR_FORM_DATA,
    HR_FORM_FAILED,
    HR_ENUMS_LOADING,
    HR_ENUMS_DATA,
    HR_ENUMS_FAILED,
    HR_FORM_SUCCESS_UPDATE,
    HR_FORM_ID_UPDATE,
    HR_CANCEL_FORM_DATA,
    HR_TIMELINE_LOADING,
    HR_TIMELINE_DATA,
    HR_TIMELINE_FAILED,
    HR_TIMELINE_CLEAR,
    HR_TIMELINE_ADD_ITEM,
    HR_TIMELINE_UPDATE_ITEM,
    HR_TIMELINE_DELETE_ITEM, HR_STAGE_PLAN_LOADING, HR_STAGE_PLAN_DATA, HR_STAGE_PLAN_FAILED, HR_STAGE_PLAN_CLEAR
} from './actions'
import {IHrState} from "./interfaces";
import {ActivityItems, ActivityUpdateItem} from "../../components/v2/activity-timeline/types/types";
import {setTimelineItem} from "../../components/v2/activity-timeline/services/setTimelineItem";
import {updateTimelineItemByIndex} from "../../components/v2/activity-timeline/services/updateTimelineItemByIndex";
import {deleteTimelineItem} from "../../components/v2/activity-timeline/services/deleteTimelineItem";
import {updateTimelineItemByItemId} from "../../components/v2/activity-timeline/services/updateTimelineItemByItemId";


const initialState: IHrState = {
    form: {
        data: null,
        isLoading: false,
        error: '',
    },
    enums: {
        data: null,
        isLoading: false,
        error: '',
    },
    cancelModal: null,
    timeline: {
        isLoading: false,
        error: '',
        data: {
            items: [],
            itemsDates: [],
            total: undefined,
            offset: undefined,
            page: undefined,
            limit: undefined,
        }
    },
    stagePlan: {
        items: [],
        isLoading: false,
        error: ''
    }
}

export const hrReducer = (
    state = initialState,
    {type, payload},
) => {
    switch (type) {
        case HR_FORM_LOADING:
            return {
                ...state,
                'id': null,
                'form': {
                    ...state['form'],
                    isLoading: true,
                    data: null,
                    error: ''
                }
            }
        case HR_FORM_DATA:
            return {
                ...state,
                'form': {
                    ...state['form'],
                    isLoading: false,
                    data: payload,
                    error: ''
                }
            }
        case HR_FORM_FAILED:
            return {
                ...state,
                'form': {
                    ...state['form'],
                    isLoading: false,
                    error: payload
                }
            }
        case HR_STAGE_PLAN_LOADING:
            return {
                ...state,
                stagePlan: {
                    isLoading: true,
                    items: [],
                    error: ''
                }
            }
        case HR_STAGE_PLAN_DATA:
            return {
                ...state,
                stagePlan: {
                    isLoading: false,
                    items: payload,
                    error: ''
                }
            }
        case HR_STAGE_PLAN_FAILED:
            return {
                ...state,
                stagePlan: {
                    items: [],
                    isLoading: false,
                    error: payload
                }
            }
        case HR_STAGE_PLAN_CLEAR:
            return {
                ...state,
                stagePlan: {
                    items: [],
                    isLoading: false,
                    error: ''
                }
            }
        case HR_FORM_SUCCESS_UPDATE:
            return {
                ...state,
                'form': {
                    ...state['form'],
                    isLoading: false,
                    error: ''
                }
            }
        case HR_FORM_ID_UPDATE:
            return {
                ...state,
                'id': payload,
                'form': {
                    ...state['form'],
                    isLoading: false,
                    error: ''
                }
            }
        case HR_CANCEL_FORM_DATA:
            return {
                ...state,
                'cancelModal': payload
            }
        case HR_ENUMS_LOADING:
            return {
                ...state,
                'enums': {
                    ...state['enums'],
                    isLoading: true,
                    data: null,
                    error: ''
                }
            }
        case HR_ENUMS_DATA:
            return {
                ...state,
                'enums': {
                    ...state['enums'],
                    isLoading: false,
                    data: payload,
                    error: ''
                }
            }
        case HR_ENUMS_FAILED:
            return {
                ...state,
                'enums': {
                    ...state['enums'],
                    isLoading: false,
                    error: payload
                }
            }
        case HR_TIMELINE_LOADING:
            return {
                ...state,
                'timeline': {
                    isLoading: true,
                    error: '',
                    data: {
                        items: [],
                        itemsDates: [],
                        total: undefined,
                        offset: undefined,
                        page: undefined,
                        limit: undefined,
                    }
                },
            }
        case HR_TIMELINE_DATA:
            return {
                ...state,
                'timeline': {
                    isLoading: false,
                    error: '',
                    data: payload
                },
            }
        case HR_TIMELINE_FAILED:
            return {
                ...state,
                'timeline': {
                    ...state['timeline'],
                    isLoading: false,
                    error: payload,
                    data: {
                        items: [],
                        itemsDates: [],
                        total: undefined,
                        offset: undefined,
                        page: undefined,
                        limit: undefined,
                    }
                },
            }
        case HR_TIMELINE_CLEAR:
            return {
                ...state,
                'timeline': {
                    isLoading: false,
                    error: '',
                    data: {
                        items: [],
                        itemsDates: [],
                        total: undefined,
                        offset: undefined,
                        page: undefined,
                        limit: undefined,
                    }
                },
            }
        case HR_TIMELINE_ADD_ITEM:
            const newActivityItems = payload as ActivityItems[];
            let timelineItems: ActivityItems[] = state.timeline.data?.items || [];
            let timelineDaysItems = state.timeline.data?.itemsDates || [];

            if (!newActivityItems.length) {
                return state;
            }

            const {
                timelineItems: timelineItemsMod,
                timelineDates: timelineDaysItemsMod
            } = setTimelineItem(newActivityItems, timelineItems, timelineDaysItems);

            return {
                ...state,
                timeline: {
                    ...state.timeline,
                    data: {
                        ...state.timeline.data,
                        items: [...timelineItemsMod],
                        itemsDates: [...timelineDaysItemsMod]
                    }
                },
            }
        case HR_TIMELINE_UPDATE_ITEM: {
            const newDataItem = payload as ActivityUpdateItem;
            let timelineItems: ActivityItems[] = state.timeline.data?.items || [];
            let timelineDaysItems = state.timeline.data?.itemsDates || [];
            const timelineItemsMod = newDataItem?.index || newDataItem?.index === 0
                ? updateTimelineItemByIndex(newDataItem, timelineItems, timelineDaysItems)
                : updateTimelineItemByItemId(newDataItem, timelineItems, timelineDaysItems);

            return {
                ...state,
                timeline: {
                    ...state.timeline,
                    data: {
                        ...state.timeline.data,
                        items: [...timelineItemsMod],
                    }
                },
            }
        }
        case HR_TIMELINE_DELETE_ITEM: {
            const deletedItem = payload as ActivityUpdateItem;
            let timelineItems: ActivityItems[] = state.timeline.data?.items || [];
            let timelineDaysItems = state.timeline.data?.itemsDates || [];

            const {
                timelineItems: timelineItemsMod,
                timelineDates: timelineDaysItemsMod
            } = deleteTimelineItem(deletedItem, timelineItems, timelineDaysItems);

            return {
                ...state,
                timeline: {
                    ...state.timeline,
                    data: {
                        ...state.timeline.data,
                        items: [...timelineItemsMod],
                        itemsDates: [...timelineDaysItemsMod]
                    }
                },
            }
        }
        default:
            return state
    }
}

