import styled from 'styled-components'
import {ITaskCalendarStageType} from "../../../../store/calendar/interfaces";

export const TaskIcon = styled.span<{$stage: ITaskCalendarStageType}>`
  background-color: ${({$stage}) => {
      switch ($stage) {
        case 'new':
            return 'green';
        case 'expired':
          return 'red';
        default:
            return 'yellow'
      }
  }};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
`
