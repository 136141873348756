import React, {useMemo, useState, useRef, useEffect} from 'react'
import {useCallback} from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
import {getDataOfType} from 'src/lib/utils/get-data-of-type'
import {SuggestItem, SuggestList, SuggestWrapper} from './styles'
import {debounce} from 'lodash'

const DADATA_TOKEN = '202f07e5711c4f92e5167758fa392229901215ac'

const formatItem = item => {
  const value: string = item?.['value'] ?? ''
  return {
    value: value, label: value,
    latitude: Number(getDataOfType(item, 'data.geo_lat', String, 0)),
    longitude: Number(getDataOfType(item, 'data.geo_lon', String, 0)),
    'address': getDataOfType(item, 'unrestricted_value', String, ''),
  }
}

const getOptions = async ({query}) => {
  if (!Boolean(query)) {
    return []
  }
  const params = {
    'query': query,
    'count': 20,
    "to_bound": {"value": "street"},
    "restrict_value": true,
    'locations': {
      country_iso_code: 'RU',
    }
  }
  const res = await fetchAPI(
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    {
      method: 'POST',
      mode: 'cors',
      credentials: undefined,
      headers: {
        Authorization: `Token ${DADATA_TOKEN}`,
      },
      body: JSON.stringify(params),
    },
  )
  return res.suggestions
}

export function InputDadata({value, onChange, schema, UISchema}: any) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const [options, setOptions] = useState<any[]>([])
  const inputRef = useRef<any>()

  const [searchValue, setSearchValue] = useState(schema?.value || value || '')
  const [isShow, setIsShow] = useState(false)

  const localOnChange = useCallback(async value => {
    setSearchValue(value.value)
    onChange({
      address: value.value,
      federal_district: value.data.federal_district,
      region: value.data.region_with_type,
      city: value.data.city || value.data.settlement_with_type,
    })
  }, [onChange])


  useEffect(debounce(() => {
    if (searchValue) {
      onChange({
        address: searchValue,
      })
    }
  }, 300), [])

  const onSearch = useCallback(async svalue => {
    setSearchValue(svalue)
    const suggestions = await getOptions({query: svalue})

    setOptions(suggestions.filter(suggestion =>
      suggestion.data.city || suggestion.data.settlement
    ))
  }, [onChange])

  const handleFocus = useCallback(() => {
    setIsShow(true)
  }, [])

  const handleBlur = useCallback(() => {
    setTimeout(() => setIsShow(false), 300)
  }, [])


  return (
    <SuggestWrapper>
      <input
        onChange={({target}) => onSearch(target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={inputRef}
        placeholder={'Введите населённый пункт'}
        defaultValue={searchValue}
        value={searchValue}
        className={'ant-input'}
        disabled={disabled}
      />
      {options.length > 0 && (
        <SuggestList isShow={isShow}>
          {options.map(suggest => (
            <SuggestItem key={suggest.value} onClick={() => localOnChange(suggest) as any}>
              {suggest.value}
            </SuggestItem>
          ))}
        </SuggestList>
      )}
    </SuggestWrapper>
  )
}

export default InputDadata
