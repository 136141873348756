import React, { useCallback, useMemo } from 'react'
import { Badge, Layout } from 'antd'
import { CalculatorOutlined, CalendarOutlined, MessageOutlined } from '@ant-design/icons'
import { NavigationButton, NavigationButtonIcon, NavigationButtons, NavigationButtonTitle } from './styles'
import { useHistory, useLocation } from 'react-router-dom'
// import {getLoadViewedTasks} from 'src/react-app/store/viewed/actions'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import Ntf from './ntf'
import { CalendarIcon } from '../../assets/icons/calendar'
import { createSession } from '../../pages_v2/tasks-calendar/model/selectors'

const createViewed = createSelector(
  (state: any) => state.viewed,
  tasks => tasks,
)

const { Header } = Layout

/*
function getHost() {
  return '/'
}
*/

export const TopMenu = () => {
  const history = useHistory()
  // const dispatch = useDispatch()
  const viewed = useSelector(createViewed)
  // const [isOpen, setOpen] = useState<boolean>(false)
  // const [clientId, setClientId] = useState(753)
  const location = useLocation()
  const { sessionData } = useSelector(createSession)
  // useEffect(() => {
  //     const controller = new AbortController()
  //     const body = {status: true, controller}
  //     dispatch(getLoadViewedTasks(body))
  //
  //     return () => {
  //         body.status = false
  //         controller.abort()
  //     }
  //
  // }, [dispatch])

  const handleCalculatorClick = useCallback(() => {
    history.push('/calculator')
  }, [history])

  const handleCalendarClick = useCallback(() => {
    history.push('/calendar')
  }, [history])

  const handleChatClick = useCallback(() => {
    // setOpen(true)
    // history.push('/chat')
  }, [history])

  //openClientFirst

  const handleNewCalendarClick = useCallback(() => {
    if (location.pathname === '/tasks/calendar') {
      history.push('/tasks')
      return
    }
    history.push('/tasks/calendar')
  }, [history, location])

  const isShowCalendarButton: boolean = useMemo(() => {
    return sessionData?.roles && (sessionData.roles.includes(19) || sessionData.roles.includes(34))
  }, [sessionData])

  return (
    <Header style={{ padding: 0, backgroundColor: '#f6f8fa' }}>
      <NavigationButtons>
        <Ntf />
        <NavigationButton onClick={handleChatClick}>
          <Badge count={0} offset={[5, 5]}>
            <NavigationButtonIcon>
              <MessageOutlined />
            </NavigationButtonIcon>
          </Badge>
          <NavigationButtonTitle>Чат</NavigationButtonTitle>
        </NavigationButton>
        <NavigationButton onClick={handleCalendarClick} style={{ display: 'none' }}>
          <Badge count={viewed['tasks']} offset={[5, 5]}>
            <NavigationButtonIcon>
              <CalendarOutlined />
            </NavigationButtonIcon>
          </Badge>
          <NavigationButtonTitle>Календарь</NavigationButtonTitle>
        </NavigationButton>
        <NavigationButton onClick={handleCalculatorClick}>
          <NavigationButtonIcon><CalculatorOutlined/></NavigationButtonIcon>
          <NavigationButtonTitle>Калькулятор</NavigationButtonTitle>
        </NavigationButton>
        {isShowCalendarButton && (
          <NavigationButton onClick={handleNewCalendarClick} style={{ marginTop: '4px' }}>
            <NavigationButtonIcon style={{ marginBottom: '5px', display: 'block' }}>
              <CalendarIcon />
            </NavigationButtonIcon>
            <NavigationButtonTitle>Календарь</NavigationButtonTitle>
          </NavigationButton>
        )}
      </NavigationButtons>
    </Header>
  )
}
