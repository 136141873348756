import styled from 'styled-components'

export const ChatBox = styled.div`
    padding: 0 0 0 15px;
    width: 100%;
    min-width: 600px;
  `

export const BadgeBox = styled.div`
    position: absolute;
    height: 100%;
    width: 45px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
  `

export const ReplyBoxStyled = styled.div`
    padding: 15px 0 0 0;
  `

export const MessageContainer = styled.div`
    height: 600px;
    overflow: auto;
    box-shadow: inset #000 0 0 20px -19px;
  `

