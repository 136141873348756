import { IChatAvitoState } from './interfaces'
import {
  CHAT_AVITO_LOADING,
  CHAT_AVITO_DATA,
  CHAT_AVITO_FAILED,
  CHAT_AVITO_ADD_ITEM,
} from './actions'
import { IAvitoChat } from '../../pages/card-hr/new/hr-chats/types'

const initialState: IChatAvitoState = {
  data: [],
  isLoading: false,
  error: '',
}

export const chatAvitoReducer = (
  state = initialState,
  {type, payload}: any,
) => {
  switch (type) {
    case CHAT_AVITO_LOADING:
      return {
        ...state,
        isLoading: true,
        error: ''
      }
    case CHAT_AVITO_DATA:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: ''
      }
    case CHAT_AVITO_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      }
    case CHAT_AVITO_ADD_ITEM:
      const newActivityItems = payload as IAvitoChat
      const existingChatIndex = state.data.findIndex(item => item.chat_id === newActivityItems.chat_id)
      const newData = existingChatIndex !== -1
        ? state.data.map((item, index) => {
          if (index === existingChatIndex) {
            return { ...item, chat: [newActivityItems.chat[0], ...item.chat] }
          }
          return item
        })
        : [...state.data, newActivityItems]

      return {
        ...state,
        data: newData,
        isLoading: false,
        error: ''
      }
    default:
      return state
  }
}
