import React, {memo, Suspense, useCallback, useEffect, useState} from "react";
import {EntityType} from "../../../../shared/types/EntityType";
import {ModalCreateTask} from "../../../../views/modal-create-task-new";
import {useDebounce} from "../../../../hooks/useDebounce/useDebounce";
import {Button, SelectProps} from "antd";
import {getCandidateIds} from "../../api/getCandidateIds";

interface TasksCalendarCreateTaskModalProps {
    entityType: EntityType,
    titleInputSearch?: string
    titleButton?: string
}

export const TasksCalendarCreateTaskModal: React.FC<TasksCalendarCreateTaskModalProps> = memo((props: TasksCalendarCreateTaskModalProps) => {
    const {entityType, titleInputSearch, titleButton = 'Добавить задачу'} = props
    const [value, setValue] = useState<string>();
    const [data, setData] = useState<SelectProps['options']>([]);
    const [isOpenModalCreateTask, setIsOpenModalCreateTask] = useState<boolean>(false)

    const fetchData = useCallback((id) => {
        return getCandidateIds({id: Number(id)})
            .then(data => {
                setData(data)
            })
    }, []);

    const debounceData = useDebounce(fetchData, 1000);
    const handleOnSearch = useCallback(
        (search) => {
            if (!search || isNaN(search)) {
                return
            }
            let val = search.replace('\D')
            if (val) {
                setValue(val)
                debounceData(val);
            }
        },
        [debounceData],
    );
    const handleOnChange = useCallback(
        (newValue) => {
            setValue(newValue?.value)
            if (!newValue) {
                setData([])
            }
        }, []
    );

    return (
        <Suspense fallback={''}>
            <Button
                onClick={() => setIsOpenModalCreateTask(true)}
            >
                {titleButton}
            </Button>
            <ModalCreateTask
                isOpen={isOpenModalCreateTask}
                onClose={() => setIsOpenModalCreateTask(false)}
                entityType={entityType}
                onSearch={handleOnSearch}
                onChange={handleOnChange}
                valueSearch={value}
                options={data}
                titleInputSearch={titleInputSearch}
            />
        </Suspense>
    )


})
