import styled from 'styled-components'

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UserProfileWrapper = styled.div`
  background: none;
  padding: 15px;
  user-select: none;
  cursor: pointer;

  .ant-layout-sider-collapsed & {
    display: none;
  }
`

export const NavigationButtons = styled.div`
  height: 64px;
`

export const NavigationButton = styled.div`
  display: inline-block;
  float: right;
  color: #C1CDD4;
  text-align: center;
  line-height: 32px;
  padding-top: 5px;
  user-select: none;
  cursor: pointer;
  width: 76px;

  &:hover {
    color: #8b9ca7;
  }
`

export const NavigationButtonIcon = styled.span`
  font-size: 24px;
  text-align: center;
  vertical-align: middle;
`

export const NavigationButtonTitle = styled.span`
  font-size: 11px;
  display: block;
  width: 100%;
  line-height: 20px;
`

export const MapWrapper = styled.div`
  padding: 10px 49px;
`

export const MapTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
`

export const MapContent = styled.div`
  border: 1px solid #9b9b9b;
  border-radius: 5px;

  padding: 24px 36px;
`

export const Copyright = styled.div`
  color: ${({ theme }) => theme.colors.grayDark};
  padding: 20px;
`

export const RateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; 
  .ant-progress-text {
    color: #fff;
    zoom: 0.9;
  }
`

export const OrganizationWrapper = styled.span`
  display: flex;
  color: #fff;
  padding: 5px;
  flex-direction: row;
  justify-content: center;
`


