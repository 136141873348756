import React, {useMemo, useCallback, useEffect, useState, forwardRef, useRef, useImperativeHandle} from 'react'
import Tabs from '../../json-form-tabs'
import getTableTransformSchemaAndFormData, {getListObject} from '../../../../lib/utils/get-table-transform-schema-and-form-data'
import ArrayTable from '../../json-form-array-table'
import FormDataEditor from '../../v2/json-form-object-editor'
import AdaptorFormDataEditor from '../../v2/json-form-object-editor/adaptor'
import {IDefaultJsonFormProps} from './interface'
import {Skeleton} from 'antd'
import {createUISchemaHorizontal, createUISchemaVertical} from '../../../../lib/utils/grid-fild-orientation'

// По аналогии filter для Array. Вызывает filter([key, value], index)
export function objectFilter(object, cb) {
  return Object.fromEntries(Object.entries(object).filter(cb))
}

// По аналогии map для Array. Вызывает map([key, value], index)
function objectMap(object, cb) {
  return Object.fromEntries(Object.entries(object).map(cb))
}

function DefaultJsonFormEditor({formData, schema, onChange, orientation = 'vertical', customTabs = {}}: IDefaultJsonFormProps, ref) {
  const [localFormData, setLocalFormData] = useState(formData)
  const [UISchema, setUISchema] = useState<any>(null)
  const formRef = useRef<any>()

  useEffect(() => {
    setLocalFormData(formData)
    return () => {}
  }, [formData, localFormData])

  useEffect(() => {
    setUISchema(orientation == 'horizontal' ? createUISchemaHorizontal(schema) : createUISchemaVertical(schema))
    return () => {}
  }, [schema])

  const [selectedProps, selectedObjectProps, formSchema] = useMemo(() => {
    const {tables, newSchema} = getTableTransformSchemaAndFormData(schema, localFormData)
    const propertiesNotRootObject = objectFilter(newSchema.properties, ([, value]) => {
      return !(value?.type === 'object')
    })
    const objectProps = getListObject(schema)
    return [tables, objectProps, {...newSchema, properties: propertiesNotRootObject}]
  }, [schema, localFormData])

  const selectedVirtualTabs = useMemo(() => {
    const customTabsVirtual = objectFilter(customTabs, ([, value]) => value?.isVirtual)
    const customTabsMap = objectMap(customTabsVirtual, ([name, value]) => [name, {...value, name}])
    return Object.values(customTabsMap)
  }, [customTabs])

  const onFormData = useCallback(({formData}) => {
    setLocalFormData(formData)
    onChange({formData, schema})
  }, [onChange, schema])

  useImperativeHandle(ref, () => ({
    validateFields: params => formRef?.current?.validateFields(params)
  }))

  return (
    <>
      {UISchema ? (
        <FormDataEditor
          ref={formRef}
          formData={localFormData}
          schema={formSchema}
          onChange={onFormData}
          UISchema={UISchema}
          orientation={orientation}
        />
      ) : (
        <Skeleton active />
      )}
      {selectedProps.length > 0 ||
      selectedObjectProps.length > 0 ||
      selectedVirtualTabs.length > 0 ? (
        <Tabs>
          {selectedProps.map(({name}, i) => {
            const CustomTab = customTabs[name]
            if (name in customTabs) {
              return <CustomTab name={name} schema={schema} formData={localFormData} onChange={onFormData} key={name + i}/>
            }
            return <ArrayTable name={name} schema={schema} formData={localFormData} onChange={onFormData} key={name + i}/>
          })}
          {selectedObjectProps.map(({name}, i) => {
            const CustomTab = customTabs[name]
            if (name in customTabs) {
              return <CustomTab name={name} schema={schema} formData={localFormData} onChange={onFormData} key={name + i}/>
            }
            return <AdaptorFormDataEditor name={name} schema={schema} formData={localFormData} onChange={onFormData} key={name + i}/>
          })}
          {selectedVirtualTabs.map(({name, schema, render: Render}, i) => (
            <Render name={name} schema={schema} key={i}/>
          ))}
        </Tabs>
      ) : null}
    </>
  )
}

export const DefaultJsonForm = forwardRef(DefaultJsonFormEditor)

