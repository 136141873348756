import React, {memo, ReactElement, ReactNode, useMemo} from "react";
import {useFormatDate} from "../../../hooks/useFormatDate";
import TimelineItemHeaderText from "../activity-timeline-item-header-text/activity-timeline-item-header-text";

interface TimelineItemHeaderProps {
    className?: string,
    title?: ReactNode,
    date?: string
}

const TimelineItemHeader: React.FC<TimelineItemHeaderProps> = memo((props: TimelineItemHeaderProps) => {
    const {className, title, date} = props;
    const formatDate = useMemo<string>(
        () => useFormatDate(date),
        [date],
    );

    return (
        <TimelineItemHeaderText className={className} title={title} text={formatDate} />
    )
})
export default TimelineItemHeader
