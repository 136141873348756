import fetchAPI from 'src/lib/utils/fetch-api'
import {getDataOfType} from 'src/lib/utils/get-data-of-type'
import {store} from 'src/browser-app'

export const SET_TASKS = 'SET_TASKS'
export const SET_COUNTS = 'SET_COUNTS'
export const SET_OBJECS = 'SET_OBJECS'

export function getLoadViewedTasks(body) {
  return async dispatch => {
    try {
      const response = await fetchAPI('/api/viewed/tasks/count')
      const count = getDataOfType(response, 'data.count', Number, 0)
      dispatch({type: SET_TASKS, count: count})
      while (body.status) {
        const response = await fetchAPI('/api/viewed/long-poling/tasks/count', {signal: body.controller.signal})
        const count = getDataOfType(response, 'data.count', Number, 0)
        dispatch({type: SET_TASKS, count: count})
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export function setCountCards(counts) {
  return async dispatch => {
    dispatch({type: SET_COUNTS, counts: counts})
  }
}

export function setObjects(objects) {
  return async dispatch => {
    dispatch({type: SET_COUNTS, objects: objects})
  }
}

setInterval(async () => {
  const state = store.getState()
  const sessionData = state.session?.['sessionData']
  if (sessionData && Object.keys(sessionData).length !== 0) {
    const response = await fetchAPI('/api/viewed/cards')
    const counts = getDataOfType(response, 'counts', Object, null)
    if (counts === null) {
      return
    }
    const {viewed} = store.getState()
    const prevCounts = viewed['counts']
    let isUpdate = false
    for (const value in prevCounts) {
      if (prevCounts[value] !== counts[value]) {
        isUpdate = true
        break
      }
    }
    if (isUpdate) {
      store.dispatch(setCountCards(counts))
    }
  }
}, 3000)
