import fetchAPI from "../../../lib/utils/fetch-api";
import {
    setCalendarData,
    setCalendarDataLoading,
    setCalendarFailed,
    setCalendarTypes,
    setCalendarTypesFailed
} from "./actions";
import {ITaskCalendarFilter} from "./interfaces";


//http://localhost:3000/api/v2/tasks?responsible=8133&dates=2024.05.01-2024.05.31 stage=new/dane/expired
//page_size=9999

export function getHrTasksList(filter?: ITaskCalendarFilter) {
    return async dispatch => {
        dispatch(setCalendarDataLoading());
        try {
            //@ts-ignore
            const params = new URLSearchParams(filter);
            params.set('page_size', '100')
            const response = await fetchAPI(`/api/v2/tasks?${params.toString()}`) || [];

            if (response['errors']) {
                let error = response['errors'];
                if (Array.isArray(error)) {
                    error = error.join();
                }
                return dispatch(setCalendarFailed(error));
            }

            return dispatch(setCalendarData(response.data));
        } catch (err) {
            return dispatch(setCalendarFailed(err as string));
        }
    }
}

export function getTaskTypeHrTasksList() {
    return async dispatch => {
        try {
            const response = await fetchAPI(`/api/v2/task/hr/enum`);
            if (response['errors']) {
                let error = response['errors'];
                if (Array.isArray(error)) {
                    error = error.join();
                }
                return dispatch(setCalendarTypesFailed(error));
            }

            return dispatch(setCalendarTypes(response || []));
        } catch (err) {
            return dispatch(setCalendarTypesFailed('ошибка загрузки типов задач: ' + err));
        }
    }
}



