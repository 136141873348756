import React, {memo, Suspense, useCallback, useState} from "react";
import {HStack} from "../../../../../ui/Stack";
import {Button} from "antd";
import {useSelector} from "react-redux";
import {getVisibleCancelModal, isVisibleCancelModal} from "../../selectors";
import {classNames} from "../../../../../lib/classNames/classNames";
import {ModalCreateTask} from 'src/react-app/views/modal-create-task-new'
import {ModalAddNote} from "../../../../../views/modal-add-notes-new";
import {Link} from "react-router-dom";


interface HrFormsHeaderProps {
    id: string | number,
    className?: string,
    dateCreated?: string,
    stage?: number,
    nextStageOnClick?: () => void,
    onClickCancelButton?: () => void,
}

const titlesButton = [
    'Взять в работу', 'Назначить собеседование', 'Оформление', 'Завершить'
]


export const HrFormsHeader: React.FC<HrFormsHeaderProps> = memo((props: HrFormsHeaderProps) => {
    const {className, id, dateCreated, stage = 0, nextStageOnClick, onClickCancelButton} = props

    const [isOpenModalCreateTask, setIsOpenModalCreateTask] = useState<boolean>(false)
    const [isOpenModalAddNote, setIsOpenModalAddNote] = useState<boolean>(false)

    const isVisibleCancelButton = useSelector(isVisibleCancelModal);
    const isVisibleCancelModalButton = (id !== 'new') && isVisibleCancelButton;
    const isDisabledButtons = id === 'new';
    const cancelModalData = useSelector(getVisibleCancelModal);


    const handleNextStageOnClick = useCallback(async () => {
        if (nextStageOnClick) {
            await nextStageOnClick?.()
        }
    }, [nextStageOnClick])

    const handleOnClickCancelButton = useCallback(() => {
        if (onClickCancelButton) {
            onClickCancelButton?.()
        }
    }, [onClickCancelButton])

    const onCloseAddNote = useCallback(() => {
        setIsOpenModalAddNote(false)
    }, [setIsOpenModalAddNote])

    return (
        <HStack gap={"8"} className={classNames('mapWrapper', {}, [className])}>
            <HStack gap={"8"} max justify={'start'} className={'h3'}>
                {
                    id === 'new' ? ('Новая запись') : (<>
                        Запись {`№ ${id}`}
                    </>)
                }
            </HStack>
            <HStack gap={"8"} max justify={'end'}>
                {
                    titlesButton[stage] && (
                        <Button onClick={handleNextStageOnClick}
                                style={{marginBottom: 16, marginTop: 16}}
                                type="default"
                                disabled={isDisabledButtons}
                        >
                            {titlesButton[stage] ?? ''}
                        </Button>
                    )
                }

                <Button
                    onClick={() => setIsOpenModalAddNote(true)}
                    disabled={isDisabledButtons}
                >
                    Добавить заметку
                </Button>
                <Suspense fallback={''}>
                    <Button
                        onClick={() => setIsOpenModalCreateTask(true)}
                        disabled={isDisabledButtons}
                    >
                        Создать задачу
                    </Button>
                    <ModalCreateTask
                        isOpen={isOpenModalCreateTask}
                        onClose={() => setIsOpenModalCreateTask(false)}
                        entityType={"hr"}
                        entityId={id}
                    />
                </Suspense>

                <ModalAddNote
                    entityId={Number(id)}
                    entityType={"hr"}
                    isOpen={isOpenModalAddNote}
                    onClose={onCloseAddNote}
                />
                {
                    isVisibleCancelModalButton && (
                        <Button onClick={handleOnClickCancelButton}
                                style={{marginBottom: 16, marginTop: 16}}
                                type="default"
                                danger>
                            {cancelModalData && cancelModalData?.title}
                        </Button>
                    )
                }
                {isDisabledButtons && (
                         <Button danger>
                             <Link to={`/hr/`}>Отмена</Link>
                         </Button>
                    )
                }
            </HStack>
        </HStack>

    )


})
