import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import React, { useMemo, useState } from 'react'
import { Table, Input, Form, Button } from 'antd'
import { CloseSquareFilled, SaveFilled, EditTwoTone } from '@ant-design/icons'
import { Checkbox } from 'antd'
import { useCallback } from 'react'
import SelectFromEnum from 'src/react-app/components/select-from-enum'

function CheckboxEdit({ value, onChange, disabled = false }: any) {
  const localOnChange = useCallback(
    ({ target }) => {
      if (typeof onChange === 'function') {
        onChange(target.checked)
      }
    },
    [onChange],
  )
  return <Checkbox checked={value} onChange={localOnChange} disabled={disabled} />
}


function TaxTypeEdit({ value, onChange, disabled = false }: any) {
  const UISchemaSelectFromEnum = useMemo(()=> {
    return {
      options: { width: 200, placeholder: 'Ставка НДС', allowClear: true, showSearch: true, disabled }
    }
  }, [disabled])
  const schema = useMemo(() => {
    return (
      {
        "type": "string",
        "title": "Ставка НДС",
        "enum": [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7"
        ],
        "oneOf": [
          {
            "const": "0",
            "title": "без НДС"
          },
          {
            "const": "1",
            "title": "НДС по ставке 0%"
          },
          {
            "const": "2",
            "title": "НДС чека по ставке 10%"
          },
          {
            "const": "3",
            "title": "НДС чека по ставке 18%"
          },
          {
            "const": "4",
            "title": "НДС чека по расчётной ставке 10/110"
          },
          {
            "const": "5",
            "title": "НДС чека по расчётной ставке 18/118"
          },
          {
            "const": "6",
            "title": "НДС чека по ставке 20%"
          },
          {
            "const": "7",
            "title": "НДС чека по расчётной ставке 20/120"
          }
        ],
        "view": {
          "name": "select-from-enum"
        }
      }
    )
  }, [])

  return <SelectFromEnum value={value} schema={schema} onChange={onChange} UISchema={UISchemaSelectFromEnum} />
}

function OrganizationFromEdit({ value, onChange, disabled = false }: any) {
  const UISchemaSelectFromEnum = useMemo(()=> {
    return {
      options: { width: 200, placeholder: 'Тип организации', allowClear: true, showSearch: true, disabled }
    }
  }, [disabled])
  const schema = useMemo(() => {
    return (
      {
        "type": "string",
        "title": "Юр.статус",
        "enum": [
          "ООО",
          "ИП"
        ],
        "oneOf": [
          {
            "const": "ООО",
            "title": "ООО"
          },
          {
            "const": "ИП",
            "title": "ИП"
          }
        ],
        "view": {
          "name": "select-from-enum"
        }
      }
    )
  }, [])

  return <SelectFromEnum value={value} schema={schema} onChange={onChange} UISchema={UISchemaSelectFromEnum} />
}

function AgentInfoTypeEdit({ value, onChange, disabled = false }: any) {
  const UISchemaSelectFromEnum = useMemo(()=> {
    return {
      options: { width: 200, placeholder: 'Тип агента', allowClear: true, showSearch: true, disabled }
    }
  }, [disabled])
  const schema = useMemo(() => {
    return (
      {
        "type": "string",
        "title": "Тип агента",
        "enum": [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7"
        ],
        "oneOf": [
          {
            "const": "1",
            "title": "банковский платёжный агент"
          },
          {
            "const": "2",
            "title": "банковский платёжный субагент"
          },
          {
            "const": "3",
            "title": "платёжный агент"
          },
          {
            "const": "4",
            "title": "платёжный субагент"
          },
          {
            "const": "5",
            "title": "поверенный"
          },
          {
            "const": "6",
            "title": "комиссионер"
          },
          {
            "const": "7",
            "title": "иной агент"
          }
        ],
        "view": {
          "name": "select-from-enum"
        }
      }
    )
  }, [])

  return <SelectFromEnum value={value} schema={schema} onChange={onChange} UISchema={UISchemaSelectFromEnum} />
}

function getNode({ inputType, editing, dataIndex, record, children }: any) {
  if (inputType === 'checkbox') {
    return editing ? <CheckboxEdit disabled={false} /> : <CheckboxEdit value={record[dataIndex]} disabled />
  }
  if (dataIndex === 'organization-from') {
    return editing ? <OrganizationFromEdit /> : <OrganizationFromEdit value={record[dataIndex]} disabled />
  }
  if (dataIndex === 'tax-type') {
    return editing ? <TaxTypeEdit /> : <TaxTypeEdit value={record[dataIndex]} disabled />
  }
  if (dataIndex === 'agent-info-type') {
    return editing ? <AgentInfoTypeEdit /> : <AgentInfoTypeEdit value={record[dataIndex]} disabled />
  }
  return editing ? <Input /> : children 
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const node = getNode({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children
  })

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Укажите ${title}`,
            },
          ]}
        >
          {node}
        </Form.Item>
      ) : node}
    </td>
  )
}

const TableRequisite = ({ rData, onChange }) => {
  const [form] = Form.useForm()
  const [data, setData] = useState(rData)
  const [editingKey, setEditingKey] = useState('')

  const isEditing = record => record.key === editingKey

  const edit = record => {
    form.setFieldsValue({
    /*  name: '',
      age: '',
      address: '',*/
      active: true,
      ...record,
    })
    setEditingKey(record.key)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const save = async key => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex(item => key === getDataOfType(item, 'key', String, null))

      if (index > -1) {
        const item = newData[index]
        //newData.forEach(item => Object.assign(item, { 'active': false }))
        item['active'] = true
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
        setEditingKey('')
        onChange(newData)
      } else {
        // newData.forEach(item => Object.assign(item, { 'active': false }))
        row['active'] = true
        newData.push(row)
        setData(newData)
        setEditingKey('')
        onChange(newData)
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const addRow = useCallback(() => {
    const newRow = {
      'name': '',
      'organization-from': '',
      'adress': '',
      'director-name': '',
      'agent-info-type': '',
      'tax-type': '',

      'inn': '',
      'invoice': '',
      'invoice-corr': '',
      'name-bank': '',
      'bik': '',
      'okpo': '',
      'active': true,
    }
    const nextData = [newRow, ...data].map((item, index) => ({ ...item, key: String(index) }))
    setData(nextData)
    form.setFieldsValue(newRow)
    setEditingKey('0')
  }, [data, form])


  // 
  const columns: any = [
    {
      title: 'Название',
      dataIndex: 'name',
      width: 300,
      editable: true,
    },
    {
      title: 'Юр. статус',
      dataIndex: 'organization-from',
      width: 300,
      editable: true,
    },
    {
      title: 'Адрес',
      dataIndex: 'adress',
      width: 300,
      editable: true,
    },
    {
      title: 'Генеральный директор',
      dataIndex: 'director-name',
      width: 300,
      editable: true,
    },
    {
      title: 'Тип агента',
      dataIndex: 'agent-info-type',
      width: 300,
      editable: true,
    },
    {
      title: 'Ставка НДС',
      dataIndex: 'tax-type',
      width: 300,
      editable: true,
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      width: '10%',
      editable: true,
    },
    {
      title: 'Расчетный счет',
      dataIndex: 'invoice',
      width: '15%',
      editable: true,
    },
    {
      title: 'Корр. счет',
      dataIndex: 'invoice-corr',
      width: '15%',
      editable: true,
    },
    {
      title: 'Название банка',
      dataIndex: 'name-bank',
      width: '10%',
      editable: true,
    },
    {
      title: 'БИК',
      dataIndex: 'bik',
      width: '10%',
      editable: true,
    },
    {
      title: 'ОКПО',
      dataIndex: 'okpo',
      width: '10%',
      editable: true,
    },
    {
      title: 'КПП',
      dataIndex: 'kpp',
      width: '10%',
      editable: true,
    },
    {
      title: 'ОГРН',
      dataIndex: 'ogrn',
      width: '10%',
      editable: true,
    },
    {
      title: 'Активен',
      dataIndex: 'active',
      width: 90,
      editable: true,
    },
    {
      fixed: 'right',
      title: 'Действие',
      width: 150,
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span style={{ display: 'flex', flexDirection: 'row' }}>
            <button
              type="button"
              onClick={() => save(record.key)}
              style={{
                marginLeft: 10,
                fontSize: 20,
                border: 'none',
                background: 'none',
                cursor: 'pointer',
              }}
            >
              <SaveFilled style={{ color: '#52acff' }} />
            </button>
            <button
              type="button"
              onClick={cancel}
              style={{
                marginLeft: 10,
                fontSize: 20,
                border: 'none',
                background: 'none',
                cursor: 'pointer',
              }}
            >
              <CloseSquareFilled style={{ color: '#52acff' }} />
            </button>
          </span>
        ) : (
          <button
            type="button"
            disabled={Boolean(editingKey)}
            onClick={() => edit(record)}
            style={{
              marginLeft: 10,
              fontSize: 20,
              border: 'none',
              background: 'none',
              cursor: 'pointer',
            }}
          >
            <EditTwoTone />
          </button>
        )
      },
    },
  ]
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'active' ? 'checkbox' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })
  return (
    <Form form={form} component={false}>
      <Button disabled={Boolean(editingKey)} onClick={addRow} style={{ marginBottom: 10 }}>
        Добавить
      </Button>
      <Table
        components={{ body: { cell: EditableCell }}}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 4500 }}
      />
    </Form>
  )
}

export default TableRequisite
