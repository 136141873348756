import fetchAPI from '../../../lib/utils/fetch-api'
import {
  setChatAvitoData,
  setChatAvitoFailed,
  setChatAvitoLoading,
} from './actions'

export function getChatAvito(id: string | number) {
  return async dispatch => {
    dispatch(setChatAvitoLoading())
    try {
      const response = await fetchAPI(`/api/avito/chat/hr/${id}`) || []

      if (response['errors'] || response?.error) {
        let error = response['errors'] || response?.error
        if (Array.isArray(error)) {
          error =  error.join()
        }
        return dispatch(setChatAvitoFailed(error))
      }
      // @ts-ignore
      return dispatch(setChatAvitoData(response))
    } catch (err) {
      return dispatch(setChatAvitoFailed(err as string))
    }
  }
}
