import React, { useCallback, useEffect, useState } from 'react'
import { Menu, Badge, Skeleton } from 'antd'
import { HStack } from '../../../../ui/Stack'
import './index.css'
import { useDispatch, useSelector } from 'react-redux'
import { getChatAvito } from '../../../../store/chat_avito/action-creators'
import { selectChatAvitoData, selectChatAvitoIsLoading } from '../selectors'
import { MessageBox } from './message-box'
import { ReplyBox } from './reply-box'
import { BadgeBox, ChatBox } from './styles'
import { IHrChats, IAvitoChat, IChat } from './types'
import { useEventSource } from '../../../../hooks/useEventSource/useEventSource'
import { addChatAvitoData } from '../../../../store/chat_avito/actions'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const HrChats: React.FC<IHrChats> = (props: IHrChats) => {
  const { id } = props
  const dispatch = useDispatch()
  const chats = useSelector(selectChatAvitoData)
  const isLoading = useSelector(selectChatAvitoIsLoading)
  const [ activeChatId, setActiveChatId ] = useState<string[]>([])
  const [ activeChat, setActiveChat ] = useState<any>([])
  const { subscribe } = useEventSource()

  const handleChatNewItems = useCallback((newItems: IAvitoChat) => {
    dispatch(addChatAvitoData(newItems))
  }, [])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      dispatch(getChatAvito(id))
      subscribe(`/api/avito/live/chat/hr/${id}`, handleChatNewItems)
    }
    return () => {
      mounted = false
    }
  }, [])

  const menuStyle = { minWidth: 150, maxWidth: 300 }

  useEffect(() =>{
    // Устанавливает активным первый чат
    if (chats && chats.length) {
      setActiveChatId([chats[0].chat_id])
      setActiveChat(chats.find(chat => chat.chat_id === activeChatId?.[0])?.chat)
    }
  },[chats])

  const changeChatHandler = useCallback(chat => {
    const key = chat.key
    setActiveChatId([key] )
    const getChat = (chats: IAvitoChat[], key: string): IChat[] => {
      if (chats) {
        return chats.find(chat => chat.chat_id === key)?.chat as IChat[]
      }
      return []
    }
    setActiveChat( getChat(chats, key) )
  }, [chats, setActiveChatId, setActiveChat])

  return (
    <>
      { isLoading
        ? (<Skeleton active={true}/>)
        :
        chats && chats.length
        ? (
          <HStack align='stretch'>
            <Menu
              style={menuStyle}
              defaultSelectedKeys={activeChatId}
              onClick={changeChatHandler}
            >
              { chats && chats.map(item =>
                <Menu.Item key={item?.chat_id}>
                  { item.name }
                  <BadgeBox>
                    <Badge count={item.count_not_read} offset={[10, 0]} />
                  </BadgeBox>
                </Menu.Item>
              )}
            </Menu>
            <ChatBox>
              <MessageBox data={activeChat} />
              <ReplyBox id={id} chat_id={activeChatId?.[0]} />
              {
                /*
                <Button onClick={addData}>add data in first chat</Button>
                <Button onClick={addNewData}>add data in new chat</Button>
                */
              }
            </ChatBox>
          </HStack>
          ) : (
            <h3 style={{ textAlign: 'center', padding: '20px 0' }}>Нет активных чатов</h3>
          )
      }
    </>
  )
}
