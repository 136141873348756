import { IAction, IErrors } from '../interfaces'
import {
  RESTORE_SESSION_REQUESTED,
  RESTORE_SESSION_FAILED,
  RESTORE_SESSION_DONE,
  DELETE_SESSION_REQUESTED,
  DELETE_SESSION_FAILED,
  DELETE_SESSION_DONE,
} from './actions'


const initialState: Object = {
  isRestoring: false,
  sessionData: null,
}

export const sessionReducer = (
  state = initialState, 
  { type, payload }: IAction<boolean | IErrors>,
) => {
  switch (type) {
    case RESTORE_SESSION_REQUESTED:
      return {
        ...state,
        isRestoring: true,
      }
    case RESTORE_SESSION_FAILED:
      return {
        ...state,
        isRestoring: false,
      }
    case RESTORE_SESSION_DONE:
      return {
        ...state,
        isRestoring: false,
        sessionData: payload['data'],
      }
    case DELETE_SESSION_REQUESTED:
      return {
        ...state,
        isDeleting: true,
      }
    case DELETE_SESSION_FAILED:
      return {
        ...state,
        isDeleting: false,
      }
    case DELETE_SESSION_DONE:
      return {
        ...state,
        isDeleting: false,
        sessionData: null,
      }
    default:
      return state
  }
}

